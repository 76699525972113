import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { MeasurementWithStage } from '@/store/types/collection';
import { TableBody, TableHead, TableRow } from '@mui/material';
import {
  TableContainer,
  TableDeleteIcon,
  TableCellDeleteStyled,
  TableCellNameStyled,
  TableCellStyled,
  TableStyled,
  TextFieldStyled,
  TableCellStyledItem,
  TableFirstCellStyled,
  StyledButton,
} from './styles';
import { PatternizerStepsKeys } from '@/pages/patternizer-page/components/patternizer-steps';
import { useSelector } from 'react-redux';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';

interface Props {
  measurements: MeasurementWithStage[];
  onAddMeasurement: () => void;
  onSizeValueChange: (id: number, value: string) => void;
  onNameChange: (id: number, name: string) => void;
  onRemoveMeasurement: (id: number) => void;
}

export const Table: FC<Props> = (props) => {
  const { measurements, onAddMeasurement, onSizeValueChange, onNameChange, onRemoveMeasurement } =
    props;

  const { t } = useTranslation();

  const { currentStep } = useSelector(getPatternizerSelector);

  const headerMemo = useMemo(() => {
    return measurements?.[0]?.measurement_values.map((el) => el.size_chart);
  }, [measurements]);

  const sortedHeaderMemo = useMemo(() => {
    if (headerMemo) {
      return [...headerMemo]?.sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0));
    }
  }, [headerMemo]);

  useEffect(() => {
    if (currentStep === PatternizerStepsKeys.MEASUREMENT && measurements.length === 0) {
      onAddMeasurement();
    }
  }, [measurements, currentStep]);

  return (
    <>
      {/* <Typography variant='body1'>
        {t('patternizerSteps.unitOfMeasurementWriteText')}
      </Typography> */}
      <TableContainer>
        <TableStyled>
          {!!sortedHeaderMemo?.length && (
            <TableHead>
              <TableRow>
                <TableFirstCellStyled></TableFirstCellStyled>
                {sortedHeaderMemo.map((el) => (
                  <TableCellStyled key={el.id + '_size'}>{el.size_name}</TableCellStyled>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {measurements?.map((el) => (
              <TableRow key={el.id}>
                <TableCellNameStyled>
                  <InputWithDelay
                    name={el?.name}
                    placeholder={t('patternizerSteps.typeSpecificMeasurement')}
                    onChange={(value) => onNameChange(el.id, value)}
                    textAlign='left'
                  />
                </TableCellNameStyled>
                {el.measurement_values.map((_) => (
                  <TableCellStyledItem key={_.id}>
                    <InputWithDelay
                      name={_?.value}
                      placeholder='-'
                      onChange={(value) => onSizeValueChange(_.id, value)}
                    />
                  </TableCellStyledItem>
                ))}
                <TableCellDeleteStyled>
                  <TableDeleteIcon onClick={() => onRemoveMeasurement(el.id)} />
                </TableCellDeleteStyled>
              </TableRow>
            ))}
          </TableBody>
        </TableStyled>
      </TableContainer>
      <StyledButton onClick={onAddMeasurement}>
        {t('patternizerSteps.addMeasurement')}
      </StyledButton>
    </>
  );
};

interface InputWithDelayProps {
  name: string;
  placeholder?: string;
  onChange: (value: string) => void;
  textAlign?: 'center' | 'left' | 'right';
}

export const InputWithDelay: FC<InputWithDelayProps> = (props) => {
  const { name, placeholder, onChange, textAlign = 'center' } = props;

  const [value, setValue] = useInputWithDelay(name, onChange, 500);

  return (
    <TextFieldStyled
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      multiline
      textAlign={textAlign}
    />
  );
};
