export interface Role {
  id: string;
  name: RoleName;
  icon: string;
  description: null;
  ip_access: null;
  enforce_tfa: boolean;
  admin_access: boolean;
  app_access: boolean;
  users: string[];
}

export const ROLE_NAMES = {
  Shopper: 'SHOPPER',
  Knitrr: 'KNITRR',
  PatternDesigner: 'PATTERN DESIGNER',
  Yarn: 'YARN',
  PhysicalStore: 'PHYSICAL STORE',
  Administrator: 'Administrator',
  Default: 'default'
} as const;

export type RoleName = (typeof ROLE_NAMES)[keyof typeof ROLE_NAMES];
