import React, { FC, ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Button } from '@/components/button/button';
import { DiagramItem } from './diagram-item';
import { DiagramCollectionItem } from '@/common/interfaces/collection.interface';
import { ResourceViewDrawer } from '@/components/resource-view-drawer/resource-view-drawer';
import { ButtonContainer, SidebarCellContainer } from '../resources-sidebar/resources-sidebar';
import { AddButton } from '../pattern-steps/components/add-step/add-step';

export interface DiagramsProps {
  diagrams?: DiagramCollectionItem[];
  onFileChange: (file: File) => void;
  onDiagramDelete: (diagramId: number) => void;
  onDiagramSave: (diagram: { name: string; id: number }) => void;
}

const Container = styled('div')({
  padding: '0.5rem',
});

const Title = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  textDecoration: 'none',
  fontWeight: '700',
  margin: 0,
}));

const ImagesContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -0.5rem 0.5rem',
});

export const Diagrams: FC<DiagramsProps> = ({
  diagrams = [],
  onFileChange,
  onDiagramDelete,
  onDiagramSave,
}) => {
  const { t } = useTranslation();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [resourceViewId, setResourceViewId] = useState<number | null>(null);

  const openFileUploadDialogHandler = () => {
    fileUploadRef.current?.click();
  };

  const onFileInputChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.files || target.files.length !== 1) {
      alert("not allowed for now")
      return;
    }
    onFileChange(target.files[0]);
    target.value = '';
  };

  return (
    <SidebarCellContainer>
      <Title>{t('resourcesSidebar.charts')}</Title>
      <ImagesContainer>
        {diagrams.map((diagram) => (
          <DiagramItem
            key={diagram.image}
            diagram={diagram}
            onOpenPreview={() => setResourceViewId(diagram.id)}
            onDelete={() => onDiagramDelete(diagram.id)}
            onSave={(diagramData: { name: string; id: number }) => onDiagramSave(diagramData)}
          />
        ))}
      </ImagesContainer>
      <input
        type='file'
        ref={fileUploadRef}
        multiple
        accept='image/png, image/jpeg'
        name='diagram-upload'
        onChange={onFileInputChangeHandler}
        hidden
      />
      <ButtonContainer>
        <AddButton onClick={openFileUploadDialogHandler}>
          {'+ '}{t('common.uploadDiagram')}
        </AddButton>
      </ButtonContainer>
      <ResourceViewDrawer
        resourceType='diagram'
        resourceId={resourceViewId}
        onClose={() => setResourceViewId(null)}
      />
    </SidebarCellContainer>
  );
};
