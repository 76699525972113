import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

export const TabItemEl = styled('span')({
  width: '100%',
  display: 'flex',
  // padding: '1rem 2.5rem 1rem 1rem',
  position: 'relative',
  '&:hover .delete-btn-container': {
    display: 'block',
  },
});

export const DeleteBtnContainer = styled('div')({
  display: 'none',
  position: 'absolute',
  top: '10px',
  right: '10px',
});

export const ButtonStyled = styled(MuiButton)({
  color: '#000',
});

export const IconBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  height: '2.5rem',
  margin: 'auto',
  padding: 0,
  color: theme.palette.grey[500],
}));

export const Title = styled('span')({
  display: 'block',
  padding: '0.125rem 0',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  textTransform: 'none',
  whiteSpace: 'normal',
  textAlign: 'left',
});

export const InputEl = styled(Input)({
  width: '100%',
  fontSize: '1rem',
  '.MuiInputBase-input': {
    padding: '0.125rem 0',
    lineHeight: '1.25rem',
    height: 'auto',
    boxSizing: 'border-box',
  },
});
