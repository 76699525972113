import { Collections } from '@/common/interfaces/collection.interface';
import { QueryMany } from '@directus/sdk';

export const DirectusFields = {
	Pattern: [
		'*, images.*',
		'*, user_created.username',
		'*, user_created.id',
		'*, pattern_steps.*',
		'*, pattern_steps.relevant_sizes.*',
		'*, pattern_steps.ressources.resources_id.*',
		'*, pattern_steps.ressources.resources_id.files.*',
		'*, pattern_steps.step_diagrams.*',
		'*, hand_work_technique.hand_work_technique_id.*',
		'*, language.language_id.*',
		'*, craft.*',
		'*, masketett.masketett_id.*',
		'*, passer_til.passer_til_id.*',
		'*, technique.technique_id.*',
		'*, type_of_project.type_of_project_id.*',
		'*, unit_measurement.*',
		'*, needle.*',
		'*, needle.needle_size.*',
		'*, needle.needle_type.*',
		'*, needle.cable_length.*',
		'*, alternatives.*',
		'*, alternatives.alternativ_items.*',
		'*, alternatives.alternativ_items.yarn.*',
		'*, alternatives.alternativ_items.color.*',
		'*, alternatives.alternativ_items.quantity_with_size.*',
		'*, main_image.*',
		'*, available_sizes.size_chart_id.*',
	],
	PatternSearch: ['id', 'name', 'main_image.*', 'user_created.*', 'pattern_price.*'],
	Resource: [
		'*, Craft.*',
		'ResourceContentType.resource_content_type_id.*, ResourceType.resource_type_id.*',
		'Technics.technique_id.*',
		'files.*',
	],
	ResourceAll: [
		'*, Craft.*',
		'ResourceContentType.resource_content_type_id.*, ResourceType.resource_type_id.*',
		'Technics.technique_id.*',
		'files.file.*',
	],
	PatternBasket: ['id', 'name', 'main_image.image', 'price', 'user_created.username', 'prices.*'],
	CurrentUser: 'fields[]=*&fields[]=country.*&fields[]=role.*',
	Order: [
		'*, currency.*',
		'pattern_ref.user_created.*',
		'pattern_ref.main_image.*',
		'pattern_ref.name',
		'pattern_ref.id',
	],

	PatternSteps: [
		'pattern_steps.*',
		'pattern_steps.relevant_sizes.*',
		'pattern_steps.ressources.resources_id.*',
		'pattern_steps.ressources.resources_id.files.*',
		'pattern_steps.step_diagrams.*',
	],
	YarnFiber: ['fiber.fiber_id.*.*'],
	Yarn: ['*, colors.*', 'yarn_manufacturer.*'],
	PatternProfile: ['id', 'status', 'name', 'images.image', 'main_image.image'],
	BoughtPatternProfile: [
		'id',
		'status',
		'amount',
		'user_created',
		'payment_ref',
		'pattern.*',
		'pattern.main_image.*',
		'pattern.available_sizes.size_chart_id.*',
	],
	YarnProfile: ['id', 'status', 'name', 'images.image', 'main_image.image'],
	Measurements: [
		'*, measurement_values.*',
		'measurement_values.*',
		'measurement_values.size_chart.*',
	],
	YarnSearch: ['id', 'name', 'main_image.*', 'user_created.*', 'yarn_price.*'],
	ResourceSearch: ['id', 'name', 'files.file', 'user_created.*'],
	YarnManufacturer: [
		'*',
		'available_country.countries_id.*',
		'shipment_methods.currency.*',
		'shipment_methods.shipment_method_id.*',
		'shipment_methods.price',
		'shipment_methods.id',
	],
	WebPages: ['*, page_items.*', '*, page_items.web_page_items_id.*'],
	Users: ['*, country.*', '*, role.*'],
	Needle: ['*, images.*', 'main_image.*'],
};

export const getQueryParamsForCollections = (type: Collections): QueryMany<unknown> => {
	const queryParams: QueryMany<unknown> = {};

	switch (type) {
		case Collections.Projects: {
			queryParams.sort = ['name' as never];
			break;
		}
		case Collections.Technics: {
			queryParams.sort = ['name' as never];
			break;
		}
		case Collections.Suitable: {
			queryParams.sort = ['sort' as never];
			break;
		}
		case Collections.Languages: {
			queryParams.sort = ['name' as never];
			break;
		}
		case Collections.Gauge: {
			queryParams.sort = ['sort' as never];
			break;
		}
		case Collections.AvailableSize: {
			queryParams.sort = ['sort' as never];
			break;
		}
		case Collections.Yarn: {
			queryParams.fields = '*,colors.*';
			break;
		}
		case Collections.Resources: {
			queryParams.sort = ['-date_created' as never];
			queryParams.fields = DirectusFields.Resource;
			break;
		}
	}

	return queryParams;
};
