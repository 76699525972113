import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import cn from 'classnames';
import { FC } from 'react';

export interface ButtonProps extends MuiButtonProps {
  colored?: boolean;
  selected?: boolean;
  remove?: boolean;
}

const ButtonEl = styled(MuiButton)<{ colored?: boolean }>(({ theme, colored }) => ({
  width: 'fit-content',
  ...theme.typography.body2,
  padding: '1rem 2.5rem',
  [theme.breakpoints.down('laptop')]: {
    padding: '0.5rem 1rem',
  },
  borderRadius: '4.375rem',
  border: '1px solid #000',
  color: '#000',
  backgroundColor: colored ? theme.palette.primary.main : 'transparent',
  textTransform: 'none',
  // flexShrink: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.button--colored': {
    backgroundColor: theme.palette.primary.main,
    ':disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.inactive,
    },
    '&:hover:not(:disabled)': { 
      backgroundColor: 'transparent' 
    },
  },
  '&.button--remove': {
    backgroundColor: '#F09E94',
    ':disabled': {
      opacity: '.5',
    },
  },
  '&.button--selected': {
    backgroundColor: 'theme.palette.secondary.main',
  },
}));

// const ButtonEl = styled(MuiButton)({
//   fontSize: '1.5rem',
//   fontWeight: '500',
//   lineHeight: '1.5rem',
//   padding: '0.5rem 1.5rem',
//   borderRadius: '4.375rem',
//   border: '2px solid #000',
//   background: 'none',
//   color: '#211E21',
//   textTransform: 'none',
//   flexShrink: 0,
//   '&:hover': {
//     backgroundColor: '#CEFF1A',
//   },
//   '&.button--colored': {
//     backgroundColor: '#CEFF1A',
//     ':disabled': {
//       opacity: '.5',
//     },
//   },
//   '&.button--remove': {
//     backgroundColor: '#F09E94',
//     ':disabled': {
//       opacity: '.5',
//     },
//   },
//   '&.button--selected': {
//     backgroundColor: '#F6F4ED',
//   },
// });

export const Button: FC<ButtonProps> = ({
  children,
  selected = false,
  colored = false,
  remove = false,
  className,
  ...rest
}) => {
  return (
    <ButtonEl
      className={cn(className, colored && 'button--colored', selected && 'button--selected', remove && 'button--remove')}
      {...rest}
    >
      {children}
    </ButtonEl>
  );
};
