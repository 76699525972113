import { PATH } from '@/common/constants/path';
import { TermsPrivacyAboutPageType } from '@/pages/terms-privacy-about-page/terms-privacy-about-page';
import { styled, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../assets/logo-xl.svg';
import LogoMobile from '../../assets/logo.svg';

export interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const { t } = useTranslation();


  const Footer = styled('footer')(({ theme }) => ({
    borderTop: '1px solid #000',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 5rem 2rem 2rem',
    width: '100%',
    maxWidth: '120rem',
    margin: '0 auto',
    columnGap: '5rem',
    [theme.breakpoints.down('desktop')]: {
      columnGap: '2rem',
      padding: '2rem',
    },
    [theme.breakpoints.down('tablet')]: {
      display: "none"
    },
  }));

  const LogoImage = styled('img')(({ theme }) => ({
      [theme.breakpoints.down('desktopL')]: {
        width: '15rem',
      },
      [theme.breakpoints.down('desktop')]: {
        width: '12rem',
      },
      [theme.breakpoints.down('laptop')]: {
        display: 'none',
      },
    }));

  const LogoImageMobile = styled('img')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('laptop')]: {
      display: 'block',
      width: '12rem',
    },
  }));

  const ResponsiveContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    columnGap: '5rem',
    [theme.breakpoints.down('desktop')]: {
      columnGap: '2rem',
    },
    [theme.breakpoints.down('laptop')]: {
      flexDirection: 'column',
      rowGap: '2rem',
    },
  }));

  const ListContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    paddingTop: '4rem',
    [theme.breakpoints.down('desktopL')]: {
      paddingTop: '2rem',
    },
    [theme.breakpoints.down('desktop')]: {
      padding: '0',
    },
    [theme.breakpoints.down('laptop')]: {
      flexWrap: 'wrap',
      rowGap: '2rem',
    },
  }));

  const List = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('laptop')]: {
      minWidth: '12rem',
    },
  }));

  const LastList = styled(List)(({ theme }) => ({
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('laptop')]: {
      textAlign: 'initial',
      minWidth: '12rem',
    },
  }));

  const FooterText = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    padding: '0.25rem',
  }));

  const GroupTitle = styled(FooterText)({
    fontWeight: 'bold',
  });

  const StyledRouterLink = styled(RouterLink)({
    textDecoration: 'none',
  });

  const CopyrightText = styled(FooterText)({
    textAlign: 'right',
    padding: '2rem 0',
  });

  const InnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  });

  return (
    <Footer>
      <ResponsiveContainer>
        <RouterLink to={`${PATH.HOME}`}>
          <LogoImage src={Logo} alt={t('main.title')} />
          <LogoImageMobile src={LogoMobile} alt={t('main.title')} />
        </RouterLink>
        <ListContainer>
          <InnerContainer>
            <List>
              <GroupTitle>{t('footer.products')}</GroupTitle>
              <StyledRouterLink to={`${PATH.PATTERNS_START}`}>
                <FooterText>{t('footer.patternizer')}</FooterText>
              </StyledRouterLink>
              <StyledRouterLink to={`${PATH.YARNIVERSE_START}`}>
                <FooterText>{t('footer.yarniverse')}</FooterText>
              </StyledRouterLink>
              <StyledRouterLink to={`${PATH.KNITSHOP}`}>
                <FooterText>{t('footer.stores')}</FooterText>
              </StyledRouterLink>
              <StyledRouterLink to={`${PATH.LEARKNIT}`}>
                <FooterText>{t('footer.learknit')}</FooterText>
              </StyledRouterLink>
            </List>
            <List>
              <GroupTitle>{t('footer.company')}</GroupTitle>
              <StyledRouterLink to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.ABOUT}`}>
                <FooterText>{t('footer.about')}</FooterText>
              </StyledRouterLink>
              <StyledRouterLink to={`${PATH.HOME}`}>
                <FooterText>{t('footer.team')}</FooterText>
              </StyledRouterLink>
            </List>
          </InnerContainer>
          <InnerContainer>
            <List>
              <GroupTitle>{t('footer.legal')}</GroupTitle>
              <StyledRouterLink to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.PRIVACY}`}>
                <FooterText>{t('footer.privacyPolicy')}</FooterText>
              </StyledRouterLink>
              <StyledRouterLink to={`${PATH.TERMS_PRIVACY_ABOUT}?type=${TermsPrivacyAboutPageType.TERMS}`}>
                <FooterText>{t('footer.termsConditions')}</FooterText>
              </StyledRouterLink>
            </List>
            <LastList>
              <GroupTitle>{t('footer.contact')}</GroupTitle>
              <StyledRouterLink to='tel:+4793443099'>
                <FooterText>+47 93443099</FooterText>
              </StyledRouterLink>
              <StyledRouterLink to={`mailto:${t('footer.emailAddress')}`}>
                <FooterText>{t('footer.emailAddress')}</FooterText>
              </StyledRouterLink>
            </LastList>
          </InnerContainer>
        </ListContainer>
      </ResponsiveContainer>
      <CopyrightText>Knitry {new Date().getFullYear()}</CopyrightText>

      {/* <StackLinksAndText>
        <List component='nav'>
          <ListItemStyled component='div'>
            <ListItemText primary={t('footer.contact')} />
          </ListItemStyled>
          <ListItemStyled component='div'>
            <RouterLink to={`mailto:${t('footer.emailAddress')}`}>{t('footer.emailAddress')}</RouterLink>
          </ListItemStyled>
        </List>
      </StackLinksAndText> */}
    </Footer>
  );
};
