import {
  PatternImageCollectionItem,
  YarnImageCollectionItem,
} from "@/common/interfaces/collection.interface";
import { Button } from "@/components/button/button";
import { getAssetBackendUrl } from "@/utils/getAssetBackendUrl";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  ButtonText,
  Container,
  Header,
  MainCheckbox,
  MainCheckboxContainer,
  Next,
  Picture,
  PictureContainer,
  Prev,
} from "./styles";

interface Props {
  mainImageId?: number;
  images: PatternImageCollectionItem[] | YarnImageCollectionItem[];
  onUpload: (image: File) => void;
  onMain: (image: any) => void;
  buttonText: string;
}

export const PhotoUploader: FC<Props> = (props) => {
  const { mainImageId, images, onUpload, onMain, buttonText, ...rest } = props;

  const [currentImage, setCurrentImage] = useState<
    PatternImageCollectionItem | YarnImageCollectionItem | null
  >();

  const ref = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];

    onUpload(file);

    event.target.value = "";
  };

  const setMainImage = () => {
    if (!currentImage) return;
    onMain(currentImage);
  };

  useEffect(() => {
    if (images.length) {
      setCurrentImage(images[0]);
    }
    if (images.length === 1) {
      setMainImage();
    }
  }, [images]);

  const onNextImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === currentImage?.id,
    );
    if (currentIndex === images.length - 1) {
      setCurrentImage(images[0]);
    } else {
      setCurrentImage(images[currentIndex + 1]);
    }
  };

  const onPrevImage = () => {
    const currentIndex = images.findIndex(
      (image) => image.id === currentImage?.id,
    );
    if (currentIndex === 0) {
      setCurrentImage(images[images.length - 1]);
    } else {
      setCurrentImage(images[currentIndex - 1]);
    }
  };

  return (
    <Container {...rest}>
      {images.length ? (
        <PictureContainer>
          <Header>
            <ButtonText onClick={onClick}>Upload image</ButtonText>
            <MainCheckboxContainer>
              <ButtonText>Set as main picture</ButtonText>
              <MainCheckbox
                onClick={setMainImage}
                className={currentImage?.id === mainImageId ? "checked" : ""}
              ></MainCheckbox>
            </MainCheckboxContainer>
          </Header>
          <Picture src={getAssetBackendUrl(currentImage?.image || "")} />
          <Prev onClick={onPrevImage} />
          <Next onClick={onNextImage} />
        </PictureContainer>
      ) : (
        <>
          <Button onClick={onClick}>{buttonText}</Button>
        </>
      )}
      <input
        ref={ref}
        type="file"
        hidden
        accept="image/*"
        onChange={onChange}
      />
    </Container>
  );
};
