import { NeedleImageCollectionItem } from '@/common/interfaces/collection.interface';

export enum NeedleSteps {
  // START = 'START',
  NEEDLE = 'NEEDLE',
  CATEGORIZATION = 'CATEGORIZATION',
  IMAGES = 'IMAGES',
  ADVERTISEMENT = 'ADVERTISEMENT',
}

export enum NeedleDataFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  IMAGES = 'images',
  MAIN_IMAGE = 'main_image',
  PRICE = 'price',
}

export enum NeedleCategorizationItems {
  NEEDLE_TYPE = 'needleType',
  NEEDLE_SIZE = 'needleSize',
  NEEDLE_LENGTH = 'needleLength',
  NEEDLE_MATERIAL = 'needleMaterial',
}

export type FilesToUpload = { [fileId: string]: File };

export type NeedleData = {
  [NeedleDataFields.NAME]: string;
  [NeedleDataFields.DESCRIPTION]: string;
  [NeedleDataFields.IMAGES]: FilesToUpload;
  [NeedleDataFields.MAIN_IMAGE]: string | number;
  [NeedleDataFields.PRICE]: number;
};

export type EditNeedleData = Partial<NeedleData> & {
  id?: number;
  imagesToDelete?: Array<number>;
  type?: number;
  size?: number;
  material?: number;
  cable_length?: number;
  status?: 'draft' | 'published';
};

export interface NeedleWithStage {
  date_created?: string;
  date_updated?: null;
  description?: string;
  files?: {
    file: any;
  }[];
  id?: number;
  name?: string;
  sort?: null;
  status?: 'draft' | 'published' | 'archived';
  user_created?: string;
  user_updated?: null;

  price?: number;
  images?: NeedleImageCollectionItem[];
  main_image?: NeedleImageCollectionItem;

  type?: number;
  size?: number;
  cable_length?: number;
  material?: number;
}
