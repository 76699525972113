import { ROLE_NAMES, RoleName } from '@/common/interfaces/role.interface';
import { PATH } from '@/common/constants/path';

const alwaysOn = new Date('2024-09-01T12:00:00+02:00');
const userLaunch1 = new Date('2024-10-01T12:00:00+02:00');
const userLaunch2 = new Date('2024-10-15T12:00:00+02:00');
const featureLaunch1 = new Date('2024-10-01T12:00:00+02:00');
const featureLaunch2 = new Date('2024-10-15T12:00:00+02:00');
// const laterLaunch = new Date('2025-10-01T12:00:00+02:00');

export type UserRole = RoleName | 'default';
export type LaunchKey = UserRole | PATH;

export const launchDates: Record<LaunchKey, Date> = {
  [ROLE_NAMES.Shopper]: userLaunch2,
  [ROLE_NAMES.Knitrr]: userLaunch2,
  [ROLE_NAMES.PatternDesigner]: userLaunch1,
  [ROLE_NAMES.Yarn]: userLaunch1,
  [ROLE_NAMES.PhysicalStore]: userLaunch2,
  [ROLE_NAMES.Administrator]: userLaunch1,
  default: userLaunch2,
  [PATH.BASKET]: featureLaunch1,
  [PATH.FORGOT_PASSWORD]: alwaysOn,
  [PATH.HOME]: alwaysOn,
  [PATH.KNITSHOP]: featureLaunch2,
  [PATH.LEARKNIT]: featureLaunch2,
  [PATH.NEEDLE]: featureLaunch1,
  [PATH.NEEDLE_CREATE]: featureLaunch1,
  [PATH.NEEDLE_EDIT]: featureLaunch1,
  [PATH.NEEDLE_START]: featureLaunch1,
  [PATH.NEEDLES]: featureLaunch1,
  [PATH.NEW_PAYMENT_WALL]: featureLaunch1,
  [PATH.NOT_FOUND]: alwaysOn,
  [PATH.PATTERN]: featureLaunch1,
  [PATH.PATTERNS]: featureLaunch1,
  [PATH.PATTERNS_START]: featureLaunch1,
  [PATH.PAYMENT_ERROR]: featureLaunch1,
  [PATH.PAYMENT_PAGE]: featureLaunch1,
  [PATH.PAYMENT_SUCCESS]: featureLaunch1,
  [PATH.PAYMENT_WALL]: featureLaunch1,
  [PATH.PAYMENT_WALL_STRIPE]: featureLaunch1,
  [PATH.PAYMENT_WALL_STRIPE_SUCCESS]: featureLaunch1,
  [PATH.PRELAUNCH]: alwaysOn,
  [PATH.PROFILE]: featureLaunch1,
  [PATH.PROFILE_SETTING]: featureLaunch1,
  [PATH.PROMOTED_PATTERNS]: featureLaunch1,
  [PATH.PROMOTED_YARN]: featureLaunch1,
  [PATH.RESOURCE_UPLOAD]: featureLaunch1,
  [PATH.RESOURCE_VIEWER]: featureLaunch1,
  [PATH.SEARCH]: alwaysOn,
  [PATH.SIGNIN]: alwaysOn,
  [PATH.SIGNUP]: alwaysOn,
  [PATH.SIGNUP_SUCCESS]: alwaysOn,
  [PATH.STEPS_VIEWER]: featureLaunch1,
  [PATH.SUCCESS]: alwaysOn,
  [PATH.TERMS_PRIVACY_ABOUT]: alwaysOn,
  [PATH.YARN]: featureLaunch1,
  [PATH.YARNIVERS]: featureLaunch1,
  [PATH.YARNIVERSE_START]: featureLaunch1,
};
