import CloseIcon from '@mui/icons-material/Close';
import { styled, Table as MuiTable, TableCell, TextField as MuiTextField } from '@mui/material';
import { Button } from '@/components/button/button';

export const TableContainer = styled('div')({
  overflow: 'hidden',
});

export const TableStyled = styled(MuiTable)({
  width: '100%',
  marginBottom: '3rem',
});

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  ...theme.typography.h4,
  border: 'none',
  padding: '0.5rem',
  textAlign: 'center',
  fontWeight: 800,
}));

export const TableFirstCellStyled = styled(TableCellStyled)({
  width: '20rem',
});

export const TableCellNameStyled = styled(TableCellStyled)({
  border: '1px solid #000',
});

export const TableCellStyledItem = styled(TableCellNameStyled)({
  width: '10rem',
  });

  interface TextFieldStyledProps {
    textAlign?: 'center' | 'left' | 'right';
  }

export const TextFieldStyled = styled(MuiTextField)<TextFieldStyledProps>(({ theme, textAlign }) => ({
  height: '100%',
  width: '100%',
  '& fieldset': {
    border: 'none',
  },
  '& .MuiInputBase-input': {
    ...theme.typography.body2,
    textAlign: textAlign,
  },
  '& .MuiInputBase-root': {
    padding: 0,
  },
}));

export const TableCellDeleteStyled = styled(TableCell)({
  border: 'none',
  padding: '0.5rem',
});

export const TableDeleteIcon = styled(CloseIcon)({
  cursor: 'pointer',
});

export const StyledButton = styled(Button)({
  marginBottom: '1rem',
  '&:hover': {
    backgroundColor: 'inherit',
  },
});
