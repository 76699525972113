import { TranslatePatternRequest, UploadPatternRequest } from '@/common/interfaces/api/upload-pattern.dto';
import {
  LanguageCollectionItem,
  PatternImageCollectionItem,
} from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { User } from '@/common/interfaces/user.interface';
import {
  PatternStepWithStage,
  PublishPatternPayload,
  ResourceWithStage,
  UpdatePatternStepDiagramPayload,
} from '@/store/types/pattern';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreatePatternStepPayload,
  DeletePatternStepDiagramPayload,
  DeletePatternStepPayload,
  GetPatternDataPayload,
  GetPatternForEditPayload,
  PatternDataState,
  UpdatePatternStepPayload,
  UpdatePatternStepsOrderPayload,
  UploadPatternImagePayload,
  UploadPatternStepDiagramPayload,
} from '../types/pattern';
import { logoutSuccessAction } from './authorization.reducer';

export interface PatternState {
  isLoading: boolean;
  data: Pattern;
  AvailableSize: number[];
  uploadPatternParams: { pdfFile: File | null };
  images: PatternImageCollectionItem[];
  languagesLoading: boolean;
  languagesList: LanguageCollectionItem[];
  selectedLanguage: string;
  designer: User | null;
  steps: PatternStepWithStage[];
  ressources: ResourceWithStage[];
}

export interface PaymentState {
  isLoading: boolean;
  data: any;
}

const initialState: PatternState = {
  isLoading: false,
  data: {},
  AvailableSize: [],
  uploadPatternParams: {
    pdfFile: null,
  },
  images: [],
  languagesLoading: false,
  languagesList: [],
  selectedLanguage: 'en',
  designer: null,
  steps: [],
  ressources: [],
};

export const patternSlice = createSlice({
  name: 'pattern',
  initialState,
  reducers: {
    setPatternDataAction: (state, _: PayloadAction<Pattern & { callback?: () => void }>) => {
      state.isLoading = true;
    },
    setPatternDataSuccessAction: (state, action: PayloadAction<PatternDataState>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPatternDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },

    setAvailableSizeAction: (state, action: PayloadAction<number>) => {
      if (!state.AvailableSize.includes(action.payload)) {
        state.AvailableSize = [...state.AvailableSize, action.payload];
      }
    },
    unsetAvailableSizeAction: (state, action: PayloadAction<number>) => {
      state.AvailableSize = state.AvailableSize.filter(x => x !== action.payload);
    },

    setKeywordsAction: (state, action: PayloadAction<string[]>) => {
      state.data.keywords = action.payload;
    },
    updatePatternDataAction: (state, action: PayloadAction<Partial<Pattern>>) => {
      state.data = { ...state.data, ...action.payload };
    },
    //pattern upload
    setUploadPatternParams: (state, action: PayloadAction<{ pdfFile: File | null }>) => {
      state.uploadPatternParams = action.payload;
    },
    uploadPatternAction: (state, _: PayloadAction<UploadPatternRequest>) => {
      state.isLoading = true;
      state.uploadPatternParams.pdfFile = null;
    },
    getProcessingStatusAction: (_state, _: PayloadAction<{ patternId: number, callback?: () => void }>) => {
    },
    uploadPatternSuccessAction: (state) => {
      state.isLoading = false;
      state.data.isManualPattern = false;
      state.data.status = "processing";
    },
    uploadPatternErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    setPatternSteps: (state, action: PayloadAction<PatternStepWithStage[]>) => {
      state.steps = action.payload;
    },
    //pattern language
    getPatternLanguagesAction: (state) => {
      state.languagesLoading = true;
    },
    getPatternLanguagesSuccessAction: (state, action: PayloadAction<LanguageCollectionItem[]>) => {
      state.languagesLoading = false;
      state.languagesList = action.payload;
    },
    getPatternLanguagesErrorAction: (state, _: PayloadAction<any>) => {
      state.languagesLoading = false;
    },
    setSelectedPatternLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    //translate pattern
    translatePatternAction: (state, _: PayloadAction<TranslatePatternRequest>) => {
      state.isLoading = true;
    },
    translatePatternSuccessAction: (state, action: PayloadAction<PatternStepWithStage[]>) => {
      state.isLoading = false;
      state.steps = action.payload;
    },
    translatePatternErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },

    uploadPatternImageAction: (state, _: PayloadAction<UploadPatternImagePayload>) => {
      state.isLoading = true;
    },
    uploadPatternImageSuccessAction: (state) => {
      state.isLoading = false;
    },
    uploadPatternImageErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get pattern image data
    getPatternImagesAction: (state, _: PayloadAction<GetPatternDataPayload>) => {
      state.isLoading = true;
    },
    getPatternImagesSuccessAction: (state, action: PayloadAction<PatternImageCollectionItem[]>) => {
      state.isLoading = false;
      state.images = action.payload;
    },
    getPatternImagesErrorAction: (state) => {
      state.isLoading = false;
    },
    //create step
    createPatternStepsAction: (state, _: PayloadAction<CreatePatternStepPayload>) => {
      state.isLoading = true;
    },
    createPatternStepsSuccessAction: (state) => {
      state.isLoading = false;
    },
    createPatternStepsErrorAction: (state) => {
      state.isLoading = false;
    },

    //update step
    updatePatternStepAction: (state, _: PayloadAction<UpdatePatternStepPayload>) => {
      state.isLoading = true;
    },
    updatePatternStepSuccessAction: (state) => {
      state.isLoading = false;
    },
    updatePatternStepErrorAction: (state) => {
      state.isLoading = false;
    },

    addPatternStepSize: (state, action: PayloadAction<{ stepId: number, size: number }>) => {
      const currentStep = state.steps.find(x => x.id === action.payload.stepId);
      currentStep?.relevant_sizes.push(action.payload.size);
    },
    removePatternStepSize: (state, action: PayloadAction<{ stepId: number, size: number }>) => {
      const currentStep = state.steps.find(x => x.id === action.payload.stepId);
      if (currentStep) currentStep.relevant_sizes =
          currentStep?.relevant_sizes.filter(x => x !== action.payload.size);
    },

    //update step order
    updatePatternStepsOrderAction: (state, _: PayloadAction<UpdatePatternStepsOrderPayload>) => {
      state.isLoading = true;
    },
    updatePatternStepsOrderSuccessAction: (state) => {
      state.isLoading = false;
    },
    updatePatternStepsOrderErrorAction: (state) => {
      state.isLoading = false;
    },

    //delete step
    deletePatternStepAction: (state, _: PayloadAction<DeletePatternStepPayload>) => {
      state.isLoading = true;
    },
    deletePatternStepSuccessAction: (state) => {
      state.isLoading = false;
    },
    deletePatternStepErrorAction: (state) => {
      state.isLoading = false;
    },

    uploadPatternStepDiagramAction: (
      state,
      _: PayloadAction<UploadPatternStepDiagramPayload>,
    ) => {
      state.isLoading = true;
    },
    uploadPatternStepDiagramsSuccessAction: (state) => {
      state.isLoading = false;
    },
    uploadPatternStepDiagramsErrorAction: (state) => {
      state.isLoading = false;
    },

    //update step diagram
    updateStepDiagramAction: (state, _: PayloadAction<UpdatePatternStepDiagramPayload>) => {
      state.isLoading = true;
    },
    updateStepDiagramSuccessAction: (state) => {
      state.isLoading = false;
    },
    updateStepDiagramErrorAction: (state) => {
      state.isLoading = false;
    },

    //delete step diagram
    deleteStepDiagramAction: (state, _: PayloadAction<DeletePatternStepDiagramPayload>) => {
      state.isLoading = true;
    },

    deleteStepDiagramSuccessAction: (state) => {
      state.isLoading = false;
    },
    deleteStepDiagramErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get Pattern for edit
    getPatternForEditAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getPatternForEditSuccessAction: (state, action: PayloadAction<GetPatternForEditPayload>) => {
      state.isLoading = false;
      state.data = action.payload.pattern;
      state.AvailableSize = action.payload.availableSizes.map(x => x.id);
    },
    getPatternForEditErrorAction: (state) => {
      state.isLoading = false;
    },

    // Clear Data
    clearPatternDataAction: () => {
      return initialState;
    },

    // Get designer
    getPatternDesignerAction: (state, _: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getPatternDesignerSuccessAction: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.designer = action.payload;
    },
    getPatternDesignerErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get pattern steps
    getPatternStepsAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getPatternStepsSuccessAction: (state, action: PayloadAction<PatternStepWithStage[]>) => {
      state.isLoading = false;
      state.steps = action.payload;
      state.data.pattern_steps = action.payload.map(({ id }) => id);
    },
    getPatternStepsErrorAction: (state) => {
      state.isLoading = false;
    },

    // Publish pattern
    publishPatternAction: (state, _: PayloadAction<PublishPatternPayload>) => {
      state.isLoading = true;
    },
    publishPatternSuccessAction: (state) => {
      state.isLoading = false;
    },
    publishPatternErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  setPatternDataAction,
  setPatternDataSuccessAction,
  setPatternDataErrorAction,
  setAvailableSizeAction,
  unsetAvailableSizeAction,
  setKeywordsAction,
  updatePatternDataAction,
  uploadPatternAction,
  getProcessingStatusAction,
  uploadPatternSuccessAction,
  uploadPatternErrorAction,
  setUploadPatternParams,
  setPatternSteps,
  setSelectedPatternLanguage,
  getPatternLanguagesAction,
  getPatternLanguagesSuccessAction,
  getPatternLanguagesErrorAction,
  translatePatternAction,
  translatePatternSuccessAction,
  translatePatternErrorAction,
  uploadPatternImageAction,
  uploadPatternImageSuccessAction,
  uploadPatternImageErrorAction,
  getPatternImagesAction,
  getPatternImagesSuccessAction,
  getPatternImagesErrorAction,
  createPatternStepsAction,
  createPatternStepsErrorAction,
  createPatternStepsSuccessAction,
  getPatternForEditAction,
  getPatternForEditErrorAction,
  getPatternForEditSuccessAction,
  clearPatternDataAction,
  getPatternDesignerAction,
  getPatternDesignerErrorAction,
  getPatternDesignerSuccessAction,
  getPatternStepsAction,
  getPatternStepsErrorAction,
  getPatternStepsSuccessAction,
  updatePatternStepAction,
  updatePatternStepSuccessAction,
  updatePatternStepErrorAction,
  uploadPatternStepDiagramAction,
  uploadPatternStepDiagramsErrorAction,
  uploadPatternStepDiagramsSuccessAction,
  addPatternStepSize,
  removePatternStepSize,
  publishPatternAction,
  publishPatternErrorAction,
  publishPatternSuccessAction,
  deleteStepDiagramAction,
  deleteStepDiagramSuccessAction,
  deleteStepDiagramErrorAction,
  updateStepDiagramAction,
  updateStepDiagramSuccessAction,
  updateStepDiagramErrorAction,
  deletePatternStepAction,
  deletePatternStepSuccessAction,
  deletePatternStepErrorAction,
  updatePatternStepsOrderAction,
  updatePatternStepsOrderSuccessAction,
  updatePatternStepsOrderErrorAction,
} = patternSlice.actions;
export const patternReducer = patternSlice.reducer;
