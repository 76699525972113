import { PageContainer } from '@/components/page-container/page-container';
import {
  addMeasurementAction,
  getMeasurementsByPatternAction,
  removeMeasurementAction,
  updateMeasurementNameAction,
  updateMeasurementsAction,
  updateMeasurementSizeAction,
} from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NextButton } from '../next-button';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { Table } from './components/table';
import { Units } from './components/units';
import { styled, Typography } from '@mui/material';
import { t } from 'i18next';

export interface MeasurementProps {
  onPressNextButton: () => void;
}

export const Measurement: FC<MeasurementProps> = ({ onPressNextButton }) => {
  const dispatch = useDispatch();

  const pattern = useSelector(getPatternDataSelector);
  const availableSizes = useSelector(getPatternAvailableSizesSelector);
  const { Measurement } = useSelector(getCollectionDataSelector);
  const unitMeasurement = pattern?.unit_measurement;

  const missingSizes = !availableSizes || availableSizes.length === 0;
  const isDisabled = !unitMeasurement || !availableSizes || availableSizes.length === 0;

  const { currentStep } = useSelector(getPatternizerSelector);

  const isTabOpened = useMemo(
    () => currentStep === PatternizerStepsKeys.MEASUREMENT,
    [currentStep],
  );

  useEffect(() => {
    if (!isTabOpened || !pattern.id) return;
    dispatch(getMeasurementsByPatternAction(pattern.id));
  }, [currentStep, pattern.id]);

  useEffect(() => {
    if (!isTabOpened || !pattern.id) return;
    dispatch(
      updateMeasurementsAction({
        patternId: pattern.id,
        measurements: Measurement,
        availableSizes: availableSizes,
      }),
    );
  }, [availableSizes, pattern.id]);

  const handleNameChange = (id: number, name: string) => {
    dispatch(updateMeasurementNameAction({ id, name }));
  };

  const handleSizeValueChange = (id: number, value: string) => {
    dispatch(updateMeasurementSizeAction({ id, value }));
  };

  const handleAddMeasurement = () => {
    if (!pattern?.id) return;
    dispatch(
      addMeasurementAction({
        patternId: pattern?.id,
        availableSizes: availableSizes,
      }),
    );
  };

  const handlerRemoveMeasurement = (id: number) => {
    if (!pattern?.id) return;
    dispatch(
      removeMeasurementAction({
        id,
        patternId: pattern.id,
      }),
    );
  };

  useEffect(() => {
    if (
      !pattern.id ||
      !isTabOpened ||
      !Measurement.length ||
      Measurement[0].measurement_values.length === availableSizes.length
    )
      return;
    dispatch(
      updateMeasurementsAction({
        patternId: pattern.id,
        measurements: Measurement,
        availableSizes: availableSizes,
      }),
    );
  }, [availableSizes, pattern.id, Measurement]);

  return (
    <>
      <PageContainer borderBottom>
        <Title>{t('patternizerSteps.measurement')}</Title>
        {missingSizes
          ? <Typography variant='h4'>Select sizes under Categorization tab</Typography>
          : (
            <>
              <Units />
              {unitMeasurement &&
                <Table
                  measurements={Measurement || []}
                  onNameChange={handleNameChange}
                  onSizeValueChange={handleSizeValueChange}
                  onAddMeasurement={handleAddMeasurement}
                  onRemoveMeasurement={handlerRemoveMeasurement}
                />
                }
            </>
          )}
      </PageContainer>
      <NextButton align='right' onPressNext={onPressNextButton} disabled={isDisabled} />
    </>
  );
};

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  marginBottom:'4rem',
}));
