import { Button } from '@/components/button/button';
import { PageContainer } from '@/components/page-container/page-container';
import { getYarnManufacturerBySupplierAction } from '@/store/reducers/yarn-supplier.reducer';
import { setYarnDataAction } from '@/store/reducers/yarnivers.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import { getYarniversDataSelector } from '@/store/selectors/yarnivers.selector';
import { Grid, Input, styled, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  onPressNext: () => void;
}

const Description = styled(Typography)({
  width: '65%',
  lineHeight: '2rem',
  marginTop: '2rem',
  marginBottom: '6rem',
});

const ButtonStyled = styled(Button)({
  width: 'fit-content',
  backgroundColor: '#CEFF1A',
});

const InputStyled = styled(Input)({
  margin: '12rem 0',
  minWidth: '25%',
});

export const Start: FC<Props> = (props) => {
  const { onPressNext } = props;
  const dispatch = useDispatch();

  const yarn = useSelector(getYarniversDataSelector);

  const user = useSelector(getCurrentUserSelector);

  const yarnManufacturer = useSelector(getYarniversDataSelector);

  const [isStart, setIsStart] = useState(yarn.name ? false : true);

  const [name, setName] = useState(yarn.name);

  const handleNext = () => {
    if (isStart) {
      setIsStart(false);
    } else {
      handleDataSave();
      onPressNext();
    }
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const handleDataSave = () => {
    dispatch(setYarnDataAction({ ...yarn, name }));
  };

  useEffect(() => {
    if (yarn.name) {
      setIsStart(false);
      setName(yarn.name);
    }
  }, [yarn.name]);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(
      getYarnManufacturerBySupplierAction({
        userId: user.id,
      }),
    );
  }, []);

  return (
    <>
      <PageContainer>
        <Grid container direction='column'>
          <div>
            <Typography variant='h2'>
              {isStart ? 'Yarniverse' : yarnManufacturer?.name || user?.username}
            </Typography>
            {isStart ? (
              <Description variant='h5'>
                {/*
                  // TODO: translate
                */}
                Welcome to Yarniverse on Knitry, the dedicated marketplace for quality yarns!
                We&apos;re thrilled to have you as a supplier in our growing community. To begin
                selling your yarn, please provide us with detailed information about your products,
                including type, weight, materials.
                <br></br>
                <br></br>
                Once your information is submitted and approved, you can start showcasing your yarn
                to a global audience of knitters, designers, and craft enthusiasts who are eager for
                high-quality materials.
                <br></br>
                <br></br>
                Thank you for joining Yarniverse on Knitry. We look forward to helping you connect
                with a community that values craftsmanship and sustainability as much as you do.
                Start selling now and be part of a movement that enables a craft based society!
              </Description>
            ) : (
              <InputStyled
                placeholder={'Yarn name'}
                value={name}
                onChange={(event) => handleNameChange(event.target.value)}
              />
            )}
          </div>

          <ButtonStyled colored onClick={handleNext}>
            {isStart ? 'Start' : 'Next'}
          </ButtonStyled>
        </Grid>
      </PageContainer>
    </>
  );
};
