import { FC, memo, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Box, Tab, Tabs } from '@mui/material';
// import { Button } from '@mui/material';
import {
  createPatternStepsAction,
  getPatternStepsAction,
  updatePatternStepAction,
  deletePatternStepAction,
  updatePatternStepsOrderAction,
} from '@/store/reducers/pattern.reducer';
import { 
  getAvailableSizesForStep, 
  getPatternAvailableSizesSelector, 
  getPatternDataSelector, 
  getPatternStepsSelector, 
  getStepSelector 
} from '@/store/selectors/pattern.selector';
import { PatternStepWithStage } from '@/store/types/pattern';
import { AddStep } from './components/add-step/add-step';
import { TabItem } from './components/tab-item/tab-item';
import { DraggableTab } from './components/draggable-tab/draggable-tab';
import { RelevantSizes } from './components/relevant-sizes/relevant-sizes';
import { TabsEl, StepsContainer, PatternStepsTab, OutterBox } from './styles';
import { formatRelevantSizes } from '@/common/size-format';

export interface PatternStepsProps {
  // stepTitle: string;
  // stepContent: string;
  patternId: number;
  onStepChange: (stepId: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>);
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// export const PatternSteps: FC<PatternStepsProps> = memo(({ onStepChange, patternId, stepTitle, stepContent }) => {
export const PatternSteps: FC<PatternStepsProps> = memo(({ onStepChange, patternId }) => {
  const dispatch = useDispatch();
  const [currentStepId, setCurrentStepId] = useState<number | null>(null);
  const [dndSteps, setDndSteps] = useState<PatternStepWithStage[]>([]);
  const steps = useSelector(getPatternStepsSelector);
  const sizes = useSelector(getPatternAvailableSizesSelector);
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  {
    const instructions = useSelector(getStepSelector(currentStepId ?? -1))?.instructions;
    const formatString = useSelector(getPatternDataSelector).pattern_size_model;
    const relevantSizes = useSelector(getAvailableSizesForStep(currentStepId ?? -1));

    if (
      instructions
      && formatString
      && relevantSizes
    ) {
      for (const selectedSize of relevantSizes) {
        const str = formatRelevantSizes(instructions, formatString, relevantSizes, selectedSize);
        console.log("instruction for size id", selectedSize, str);
      }
    }
  }

  useEffect(() => {
    if (!steps.length) {
      return;
    }
    setDndSteps(steps);
  }, [steps]);

  //set initial step id
  useEffect(() => {
    if (!steps.length) {
      return;
    }
    const stepExists = !!steps.find(({ id }) => currentStepId === id);
    setCurrentStepId(stepExists ? currentStepId : steps[0].id);
  }, [steps, currentStepId]);

  useEffect(() => {
    if (currentStepId) {
      onStepChange(currentStepId);
    }
  }, [currentStepId]);

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    setCurrentStepId(+value);
  };

  const getUpdatedPatternSteps = () => {
    dispatch(getPatternStepsAction(patternId));
  };

  const addStepHandler = (stepTitle: string) => {
    dispatch(
      createPatternStepsAction({
        pattern: { id: patternId },
        name: stepTitle,
        sort: steps.length,
        relevant_sizes: sizes,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepNameChangeHandler = (stepId: number, name: string) => {
    dispatch(
      updatePatternStepAction({
        stepId,
        data: {
          name,
        },
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepRemoveHandler = (stepId: number) => {
    dispatch(
      deletePatternStepAction({
        stepId,
        callback: getUpdatedPatternSteps,
      }),
    );
  };

  const onStepOrderChangeHandler = (steps: PatternStepWithStage[]) => {
    const stepsPayload = steps.map(({ id }, sort) => ({ id, sort }));
    dispatch(
      updatePatternStepsOrderAction({ steps: stepsPayload, callback: getUpdatedPatternSteps }),
    );
  };

  const onStepDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const updatedDndSteps = [...dndSteps];
    const draggedTab = updatedDndSteps.splice(source.index, 1)[0];
    updatedDndSteps.splice(destination.index, 0, draggedTab);
    setDndSteps(updatedDndSteps);
    onStepOrderChangeHandler(updatedDndSteps);
  };

  return (
    <OutterBox>
      <Box>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label='steps names and properties' 
          TabIndicatorProps={{ style: { backgroundColor: '#000' } }}>
          <PatternStepsTab label='Steps' {...a11yProps(0)}></PatternStepsTab>
          <PatternStepsTab label='Edit' {...a11yProps(1)}></PatternStepsTab>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <StepsContainer>
          {steps.length !== 0 && (        
            <DragDropContext onDragEnd={onStepDragEnd}>
              <div style={{ display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
                <Droppable droppableId='patternSteps' direction='vertical'>
                  {({ droppableProps, innerRef, placeholder }) => (
                    <div ref={innerRef} {...droppableProps}>
                      <TabsEl
                        onChange={handleStepChange}
                        variant='scrollable'
                        scrollButtons={false}
                        value={currentStepId}
                      >
                        {dndSteps.map(({ id, name }, index) => {
                          return (
                            <DraggableTab
                              value={id}
                              index={index}
                              key={index}
                              label={
                                <TabItem
                                  title={name}
                                  onRemove={() => onStepRemoveHandler(id)}
                                  onChange={(value) => onStepNameChangeHandler(id, value)}
                                />
                              }
                            />
                          );
                        })}
                        {placeholder}
                      </TabsEl>
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          )}
          <AddStep onAdd={addStepHandler} />
        </StepsContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {currentStepId !== null && <RelevantSizes currentStepId={currentStepId} />}
      </CustomTabPanel>
    </OutterBox>
  );
});
