import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PageContainer } from '@/components/page-container/page-container';
import { TextEditor } from '@/components/text-editor/text-editor';
import { Input } from '@/components/input/input';

export const PageContainerEl = styled(PageContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '20% 50% 30%',
  gridTemplateRows: 'auto',
  padding: 0,
  borderTop: '1px solid #000',
  // [theme.breakpoints.down('tablet')]: {
  //   display: 'block',
  // },
}));

export const TopSection = styled('section')({
  borderTop: '1px solid #000',
  padding: '1.25rem 0',
});

export const TopContainer = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ContentEl = styled('div')({
  // flexGrow: 1,
  width: '100%',
  padding: '0 2rem',
  minHeight: '25rem',
});

export const StartContainer = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: '1rem 0',
  display: 'flex',
  alignItems: 'center',
  columnGap: '0.5rem',
}));

export const TabsTitle = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  margin: '0 2rem 0 0',
  // fontSize: '1.25rem',
  // fontWeight: 500,
  // minWidth: '22rem',
  // width: '22rem',
  paddingRight: '2rem',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    marginBottom: '2rem',
    paddingRight: 0,
  },
}));

export const TabsSubTitle = styled(TabsTitle)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
  margin: '0 1rem 0 0',
}));

export const TabsSection = styled('section')({
  // borderTop: '1px solid #000',
  padding: '1.25rem 0',
});

export const TabsContainer = styled('div')(({ theme }) => ({
  maxWidth: '1600px',
  padding: '0 2rem',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down('tablet')]: {
    padding: '0 1rem',
  },
}));

export const TextEditorEl = styled(TextEditor)({
  width: '100%',
  minHeight: '15rem',
  display: 'flex',
  flexDirection: 'column',
  '.ql-container': {
    flexGrow: 1,
  },
});

export const StyledInput = styled(Input)(({ theme }) => ({
  ...theme.typography.h3,
  marginBottom: '0.5rem',
  '& .MuiInputBase-input::placeholder': {
    fontStyle: 'italic',
  },
}));

export const StepName = styled('h3')(({ theme }) => ({
  display: 'inline-block',
  ...theme.typography.h3,
  margin: '2rem 0',
  paddingBottom: '0.5rem',
  paddingRight: '1rem',
  borderBottom: '1px solid #000',
}));
