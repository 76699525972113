import React, { FC, useState, ChangeEvent } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { TabItemEl, IconBtn, InputEl, Title, DeleteBtnContainer } from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

export interface TabItemProps {
  onRemove: () => void;
  onChange: (value: string) => void;
  title: string;
}

export const TabItem: FC<TabItemProps> = ({ title, onRemove, onChange }) => {
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState('');

  const handleEditMode = () => {
    setEdit(true);
    setEditValue(title);
  };

  const handleCloseEditMode = () => {
    setEdit(false);
    setEditValue('');
  };

  const handleSaveData = () => {
    onChange(editValue);
    handleCloseEditMode();
  };

  const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEditValue(target.value);
  };

  const DeleteBtn = (
    <IconBtn aria-label='close' onClick={onRemove}>
      {/* <DeleteIcon /> */}
      <CloseIcon />
    </IconBtn>
  );

  const SaveBtn = (
    <IconBtn aria-label='close' onClick={handleSaveData}>
      <SaveIcon />
    </IconBtn>
  );

  return (
    <ClickAwayListener onClickAway={handleCloseEditMode}>
      <TabItemEl onDoubleClick={handleEditMode}>
        {edit ? (
          <>
            <InputEl value={editValue} onChange={handleNameChange} autoFocus />
            {SaveBtn}
          </>
        ) : (
          <>
            <Title>{title}</Title>
            <DeleteBtnContainer className='delete-btn-container'>
              {DeleteBtn}
            </DeleteBtnContainer>
          </>
        )}
      </TabItemEl>
    </ClickAwayListener>
  );
};
