import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

export const TabEl = styled(Tab)({
  // border: '1px solid #000',
  alignItems: 'start',
  backgroundColor: '#fff',
  padding: '0.5rem',
  // marginRight: '1rem',
  marginBottom: '1rem',
  width: '100%',
  '&.Mui-selected': {
    backgroundColor: '#F6F4ED',
    fontWeight: 800,
    color: '#000',
  },
});
