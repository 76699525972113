import { Collections } from '@/common/interfaces/collection.interface';
import { Needle } from '@/common/interfaces/needle.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditNeedleData, NeedleData, NeedleSteps, NeedleWithStage } from '../types/needle';

export interface NeedleState {
  isLoading: boolean;
  currentStep: NeedleSteps;
  data: NeedleWithStage;
  createNeedleData: NeedleData;
  editNeedleData: EditNeedleData;
  editCategorizationData: {
    [Collections.NeedleType]: number | null;
    [Collections.NeedleSize]: number | null;
    [Collections.NeedleLength]: number | null;
    [Collections.NeedleMaterials]: number | null;
  };
  categorizationData: {
    [Collections.NeedleType]: number | null;
    [Collections.NeedleSize]: number | null;
    [Collections.NeedleLength]: number | null;
    [Collections.NeedleMaterials]: number | null;
  };
}

const initialState: NeedleState = {
  isLoading: false,
  // currentStep: NeedleSteps.START,
  currentStep: NeedleSteps.NEEDLE,
  createNeedleData: {
    name: '',
    description: '',
    images: {},
    main_image: '',
    price: 0,
  },
  editNeedleData: {},
  data: {
    name: '',
    description: '',
    images: [],
    main_image: undefined,
    price: 0,
  },
  editCategorizationData: {
    [Collections.NeedleType]: null,
    [Collections.NeedleSize]: null,
    [Collections.NeedleLength]: null,
    [Collections.NeedleMaterials]: null,
  },
  categorizationData: {
    [Collections.NeedleType]: null,
    [Collections.NeedleSize]: null,
    [Collections.NeedleLength]: null,
    [Collections.NeedleMaterials]: null,
  },
};

export const needleSlice = createSlice({
  name: 'needle',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<NeedleSteps>) => {
      state.currentStep = action.payload;
    },
    setNeedleData: (state, action: PayloadAction<NeedleData>) => {
      state.createNeedleData = action.payload;
    },
    setEditNeedleData: (state, action: PayloadAction<EditNeedleData>) => {
      state.editNeedleData = action.payload;
    },
    setEditNeedleCategorizationData: (
      state,
      { payload }: PayloadAction<{ collection: Collections; value: number | number[] }>,
    ) => {
      state.editCategorizationData = {
        ...state.editCategorizationData,
        [payload.collection]: payload.value,
      };
    },
    setNeedleCategorizationData: (
      state,
      { payload }: PayloadAction<{ collection: Collections; value: number | number[] }>,
    ) => {
      state.categorizationData = {
        ...state.categorizationData,
        [payload.collection]: payload.value,
      };
    },
    getNeedleDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getNeedleDataSuccessAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    getNeedleDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    editNeedleDataAction: (
      state,
      _: PayloadAction<
        EditNeedleData & {
          callback: (id?: number) => void;
        }
      >,
    ) => {
      state.isLoading = true;
    },
    editNeedleDataSuccessAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    editNeedleDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    createNeedleDataAction: (
      state,
      _: PayloadAction<Needle & { callback: (id?: number) => void }>,
    ) => {
      state.isLoading = true;
    },
    createNeedleDataSuccessAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },
    createNeedleDataErrorAction: (state, _: PayloadAction<any>) => {
      state.isLoading = false;
    },

    getNeedleByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getNeedleByIdSuccessAction: (state, action: PayloadAction<NeedleWithStage>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    getNeedleByIdErrorAction: (state) => {
      state.isLoading = false;
    },
    resetEditNeedleData: (state) => {
      state.editNeedleData = initialState.editNeedleData;
    },
    resetStoreAction: () => {
      return initialState;
    },
  },
});

export const {
  resetEditNeedleData,
  resetStoreAction,
  setCurrentStep,
  setNeedleData,
  setEditNeedleData,
  setEditNeedleCategorizationData,
  editNeedleDataAction,
  editNeedleDataSuccessAction,
  editNeedleDataErrorAction,
  createNeedleDataAction,
  createNeedleDataSuccessAction,
  createNeedleDataErrorAction,
  setNeedleCategorizationData,
  getNeedleDataAction,
  getNeedleDataSuccessAction,
  getNeedleDataErrorAction,
  getNeedleByIdAction,
  getNeedleByIdSuccessAction,
  getNeedleByIdErrorAction,
} = needleSlice.actions;
export const needleReducer = needleSlice.reducer;
