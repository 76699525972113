import { FC } from 'react';
import { styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  label?: string;
  value: any;
  checked: boolean;
  className?: string;
  onChange: (value: any) => void;
}

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '225px',
  minHeight: '47px',
  border: '1px solid #000',
  padding: '0 10px',
  cursor: 'pointer',
  margin: '10px',
});

const CheckContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  border: '1px solid #000',
  position: 'absolute',
  right: '10px',
});

const Circle = styled('div')({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: '#000',
});

const Label = styled(Typography)({
  textTransform: 'capitalize',
  marginRight: '15px',
});

export const InputButtonSelect: FC<Props> = (props) => {
  const { onChange, className, label, value, checked } = props;
  const theme = useTheme();

  return (
    <Container
      className={className}
      style={{ backgroundColor: checked ? theme.palette.secondary.main : 'transparent' }}
      onClick={() => onChange(value)}
    >
      <Label>{label}</Label>
      <CheckContainer>{!!checked && <Circle />}</CheckContainer>
    </Container>
  );
};
