import { createTheme, Theme } from '@mui/material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
    desktopL: true;
    wide: true;
  }

  interface PaletteColor {
    inactive?: string;
    text?: string;
  }

  interface SimplePaletteColorOptions {
    inactive?: string;
    text?: string;
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const breakpoints = {
  values: {
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1200,
    desktopL: 1440,
    wide: 1920,
  },
};

const colors = {
  primary: {
    main: '#CEFF1A',
    inactive: '#7F7F7F',
    text: '#000',
  },
  secondary: {
    main: '#F6F4ED',
  },
};

export const theme: Theme = createTheme({
  breakpoints: {
    values: breakpoints.values,
  },
  spacing: 16,
  typography: {
    fontFamily: 'ES Allianz, sans-serif',
    htmlFontSize: 16,
    h1: {
      color: `${colors.primary.text}`,
      fontSize: '5rem', // 80px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '4.25rem', // 68px
      },
      [`@media (max-width:${breakpoints.values.tablet - 1}px)`]: {
        fontSize: '3rem', // 48px
      },
      fontWeight: 'bold',
    },
    h2: {
      color: `${colors.primary.text}`,
      fontSize: '3.125rem', // 50px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '2,75rem', // 44px
      },
      [`@media (max-width:${breakpoints.values.tablet - 1}px)`]: {
        fontSize: '2.25rem', // 36px
      },
      fontWeight: 400,
    },
    h3: {
      color: `${colors.primary.text}`,
      fontSize: '2.5rem', // 40px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '2,125rem', // 34px
      },
      [`@media (max-width:${breakpoints.values.tablet - 1}px)`]: {
        fontSize: '1.875rem', // 30px
      },
      fontWeight: 400,
    },
    h4: {
      color: `${colors.primary.text}`,
      fontSize: '1.375rem', // 22px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '1.25rem', // 20px
      },
      [`@media (max-width:${breakpoints.values.tablet - 1}px)`]: {
        fontSize: '1.125rem', // 18px
      },
      fontWeight: 400,
    },
    h5: {
      color: `${colors.primary.text}`,
      fontSize: '1.25rem', // 20px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '1.125rem', // 18px
      },
      fontWeight: 400,
    },
    subtitle1: {
      color: `${colors.primary.text}`,
      fontSize: '5.6vw', // 106px when viewport is 1920px
      lineHeight: '100%',
      [`@media (min-width:${breakpoints.values.wide}px)`]: {
        fontSize: '106px',
      },
      [`@media (max-width:${breakpoints.values.desktop - 1}px)`]: {
        fontSize: '8.6vw', // 103px when viewport is 1200px
      },
      [`@media (max-width:${breakpoints.values.tablet - 1}px)`]: {
        fontSize: '11.6vw', // 48px when viewport is 414px
      },
      fontWeight: 700,
    },
    subtitle2: {
      color: `${colors.primary.text}`,
      fontSize: '5.6vw', // 106px when viewport is 1920px
      fontWeight: 300,
      lineHeight: '100%',
      [`@media (min-width:${breakpoints.values.wide}px)`]: {
        fontSize: '106px',
        fontWeight: 300,
      },
      [`@media (max-width:${breakpoints.values.desktop - 1}px)`]: {
        fontSize: '8.6vw', // 103px when viewport is 1200px
      },
      [`@media (max-width:${breakpoints.values.tablet - 1}px)`]: {
        fontSize: '1rem', // 16px
        fontWeight: 700,
      },
    },
    body1: {
      color: `${colors.primary.text}`,
      fontSize: '1.125rem', // 18px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '1rem', // 16px
      },
      fontWeight: 400,
    },
    body2: {
      color: `${colors.primary.text}`,
      fontSize: '1.25rem', // 20px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '1.125rem', // 18px
      },
      fontWeight: 400,
    },
    body3: {
      color: `${colors.primary.text}`,
      fontFamily: 'ES Allianz, sans-serif',
      fontSize: '1rem', // 16px
      [`@media (max-width:${breakpoints.values.laptop - 1}px)`]: {
        fontSize: '0.875rem', // 14px
      },
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: `${colors.primary.main}`,
      inactive: `${colors.primary.inactive}`,
      text: `${colors.primary.text}`,
    },
    secondary: {
      main: `${colors.secondary.main}`,
    },
  },
});
