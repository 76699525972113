import React, { FC, useState, MouseEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Popover, { PopoverProps } from '@mui/material/Popover';
import MuiButton from '@mui/material/Button/Button';
import { Button } from '@/components/button/button';
import { Input } from '@/components/input/input';
import { KeyCode } from '@/common/constants/keyCodes';

export interface AddStepProps extends Omit<PopoverProps, 'open'> {
  onAdd: (stepTitle: string) => void;
}

const ButtonStyled = styled(MuiButton)({
  color: '#000',
});

export const AddButton = styled('button')(({ theme }) => ({
  ...theme.typography.body1,
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  width: 'fit-content',
  padding: 0,
  // marginRight: '1rem',
  // minWidth: '9rem',
}));

export const AddStep: FC<AddStepProps> = ({ onAdd, ...rest }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [stepTitle, setStepTitle] = useState<string>('');

  const open = Boolean(anchorEl);

  const openPopoverHandler = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };

  const addStepHandler = () => {
    onAdd(stepTitle);
    closePopoverHandler();
  };

  const closePopoverHandler = () => {
    setAnchorEl(null);
    setStepTitle('');
  };

  const keyPressHandler = ({ key }: KeyboardEvent) => {
    if (key === KeyCode.ESC) {
      setStepTitle('');
    }
  };

  return (
    <>
      <AddButton onClick={openPopoverHandler}>
        {t('patternView.addStep')}
      </AddButton>
      <Popover
        id={open ? 'stepTitlePopover' : undefined}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={closePopoverHandler}
        {...rest}
      >
        <Input
          value={stepTitle}
          onChange={(e) => setStepTitle(e.target.value)}
          onKeyDown={keyPressHandler}
          autoFocus
        />
        <ButtonStyled onClick={closePopoverHandler}>{t('common.cancel')}</ButtonStyled>
        <ButtonStyled onClick={addStepHandler} disabled={!stepTitle} variant='contained'>
          {t('common.ok')}
        </ButtonStyled>
      </Popover>
    </>
  );
};
